import { z } from 'zod';
import { reactionsFiltersSchema } from './reactions.schemas';

export const reactionsVariants = [
  'emoji_like',
  'emoji_celebrate',
  'emoji_funny',
  'emoji_love',
  'emoji_party',
  'emoji_curious',
] as const;
export type ReactionsVariant = (typeof reactionsVariants)[number];

export const reactionEntities = ['news', 'recognition', 'comment'] as const;
export type ReactionEntity = (typeof reactionEntities)[number];

export interface Reaction {
  reaction: ReactionsVariant;
  firstName: string;
  lastName: string;
  fullName: string;
}

export type ReactionsSummary = Array<{
  count: number;
  emoji: ReactionsVariant;
}>;

export type ReactionsFilters = z.infer<typeof reactionsFiltersSchema>;
