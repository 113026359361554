import { ComponentProps, FC, ReactNode } from 'react';
import { Link as MuiLink } from '@mui/material';
import {
  LinkProps as RouterLinkProps,
  Link as RouterLink,
} from 'react-router-dom';
import { Typography } from 'melp-design/components';

interface LinkProps extends RouterLinkProps {
  label: ReactNode;
}

interface LinkAsButtonProps
  extends Omit<
    ComponentProps<typeof MuiLink<'button'>>,
    'component' | 'type' | 'underline'
  > {
  label: ReactNode;
}

export const Link: FC<LinkProps> = ({ label, ...linkProps }) => {
  return (
    <MuiLink component={RouterLink} underline="always" {...linkProps}>
      <Typography>{label}</Typography>
    </MuiLink>
  );
};

export const LinkAsButton: FC<LinkAsButtonProps> = ({
  label,
  ...linkAsButtonProps
}) => {
  return (
    <MuiLink
      component="button"
      type="button"
      underline="always"
      {...linkAsButtonProps}
    >
      <Typography component="span" textAlign="left" sx={{ display: 'block' }}>
        {label}
      </Typography>
    </MuiLink>
  );
};
