import {
  dateRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import { z } from 'zod';
import { binaries } from 'types/Common';
import { commentStatuses } from 'store/comments';
// Full import path needed for webpack to correctly resolve circular dependency starting storybook
import { reactionsVariants } from 'store/reactions/reactions.types';
import { newsSendMethods, newsStatuses } from './news.types';

export const newsFiltersSchema = z
  .object({
    search: z.string().catch(''),
    status: z.array(z.enum(newsStatuses)).catch([]),
    sendMethods: z.array(z.enum(newsSendMethods)).catch([]),
    publishedAt: dateRangeSchema,
    categories: z.array(z.string()).catch([]),
    employeeGroups: z.array(z.string()).catch([]),
    pinned: z.enum(binaries).nullable().catch(null),
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);

export const newsCategoriesFiltersSchema = z
  .object({})
  .merge(paginationFiltersSchema);

export const newsCommentsFiltersSchema = z
  .object({
    search: z.string().catch(''),
    reactions: z.array(z.enum(reactionsVariants)).catch([]),
    status: z.array(z.enum(commentStatuses)).catch([]),
    date: dateRangeSchema,
    employees: z.array(z.string()).catch([]),
    employeeGroups: z.array(z.string()).catch([]),
    news: z.array(z.string()).catch([]),
    newsCategories: z.array(z.string()).catch([]),
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);
