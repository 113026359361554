import { useMutation, useQueryClient } from '@tanstack/react-query';
import { newsKeys } from 'store/news';
import { loaders } from './comments.loaders';

export const useCreateComment = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createComment, {
    onSuccess: (_, { entity }) => {
      if (entity === 'news') {
        return queryClient.invalidateQueries(newsKeys.all);
      }
    },
  });
};

export const useUpdateCommentsStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateCommentsStatus, {
    onSuccess: () => {
      return queryClient.invalidateQueries(newsKeys.all);
    },
  });
};

export const useDeleteComments = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteComments, {
    onSuccess: () => {
      return queryClient.invalidateQueries(newsKeys.all);
    },
  });
};
