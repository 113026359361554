import { FC, useState } from 'react';
import {
  Menu as MuiMenu,
  MenuItem,
  ButtonBase,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  ExpandMore,
  type SvgIconComponent as MuiSvgIconComponent,
} from '@mui/icons-material';
import { SystemColors } from 'melp-design/style';

interface Props {
  label?: string;
  items: Array<{
    label: string;
    onClick: () => void;
    icon?: MuiSvgIconComponent;
    disabled?: boolean;
    selected?: boolean;
  }>;
  icon?: MuiSvgIconComponent;
  selectable?: boolean;
  expandIndicator?: boolean;
}

export const Menu: FC<Props> = ({
  label,
  items,
  icon: Icon,
  selectable,
  expandIndicator = true,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const isOpen = Boolean(anchorEl);

  const onClose = () => {
    setAnchorEl(null);
  };

  if (!items.length) return null;

  return (
    <div>
      <ButtonBase
        id="menu-trigger"
        aria-controls={isOpen ? 'menu-dropdown' : undefined}
        aria-haspopup
        aria-expanded={isOpen}
        sx={{
          px: label ? '10px' : '5px',
          py: '5px',
          gap: 0.5,
          minHeight: 30,
          minWidth: 30,
          color: SystemColors.black,
          backgroundColor: SystemColors.white,
          fontWeight: 500,
          fontSize: 14,
          borderRadius: '6px',
          border: `1px solid ${SystemColors.grey[90]}`,
          transitionDuration: '150ms',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: SystemColors.grey[96],
          },
        }}
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
      >
        {Icon ? <Icon sx={{ width: '0.8em', height: '0.8em' }} /> : null}
        {selectable ? items[selectedIndex].label : label}
        {expandIndicator ? (
          <ExpandMore
            sx={{
              width: '0.8em',
              height: '0.8em',
              color: SystemColors.grey[45],
              transform: `rotate(${isOpen ? '180' : '0'}deg)`,
              transitionDuration: '150ms',
              transitionProperty: 'transform',
            }}
          />
        ) : null}
      </ButtonBase>
      <MuiMenu
        id="menu-dropdown"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'menu-trigger',
          dense: true,
        }}
        PaperProps={{
          elevation: 5,
        }}
      >
        {items.map(
          ({ label, onClick, icon: Icon, disabled, selected = false }, i) => (
            <MenuItem
              onClick={() => {
                onClick();
                onClose();
                if (selectable) {
                  setSelectedIndex(i);
                }
              }}
              sx={{
                '.MuiListItemIcon-root': { minWidth: 'auto' },
                gap: 1,
                '&.Mui-selected': {
                  color: 'inherit',
                  background: SystemColors.grey[96],
                },
              }}
              selected={selectable ? i === selectedIndex : selected}
              disabled={disabled}
              key={label}
            >
              {Icon ? (
                <ListItemIcon>
                  <Icon sx={{ fontSize: '1.2em' }} />
                </ListItemIcon>
              ) : null}
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          ),
        )}
      </MuiMenu>
    </div>
  );
};
