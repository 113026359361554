import {
  IBaseNewItem,
  INewItem,
  INewsCategory,
  INewsComment,
} from 'types/income';
import { converters as tableConverters } from 'store/table';
import { convertReactionsSummary } from 'store/reactions';
import { loaders } from './news.loaders';
import { NewItem, NewsCategory, NewsComment } from './news.types';

const convertCommonNew = (item: IBaseNewItem | INewItem) => {
  return {
    id: item.id,
    name: item.name,
    parentCompanyId: item.parentCompanyId,
    publishedAt: item.publishDate,
    sentCount: item.sentTo,
    reactionsCount: item.reactionCount,
    commentsCount: item.commentCount,
    allowComments: item.allowComments,
    pinned: item.pinned,
    pinnedUntil: item.pinnedUntil || null,
    categories: item.categories ?? [],
    selectedReaction: item.selectedEmoji ?? null,
    reactions: convertReactionsSummary(item.reactions ?? []),
  } satisfies Partial<NewItem>;
};

const convertNew = (item: INewItem): NewItem => {
  return {
    ...convertCommonNew(item),
    status: item.draft ? 'draft' : 'published',
    inactiveAt: item.inactiveDate,
    seenCount: 0,
    sendMethods: item.sendMethods,
    supportedLanguages: item.translations.map((trans) => trans.language),
    translations: item.translations,
  };
};

const convertBaseNew = (item: IBaseNewItem): NewItem => {
  return {
    ...convertCommonNew(item),
    status: item.status,
    inactiveAt: null,
    seenCount: Number(item.seen) || 0,
    sendMethods: [],
    supportedLanguages: [],
    translations: [],
  };
};

const convertNews = (news: IBaseNewItem[]): NewItem[] => {
  return news.map(convertBaseNew);
};

const convertCategory = (category: INewsCategory): NewsCategory => {
  return {
    id: category.id,
    name: category.name,
    translations: category.translations,
    createdAt: category.createdAt,
    updatedAt: category.updatedAt,
  };
};

const convertCategories = (categories: INewsCategory[]): NewsCategory[] => {
  return categories.map(convertCategory);
};

const convertComment = (comment: INewsComment): NewsComment => {
  return {
    id: comment.id,
    status: comment.status,
    date: comment.date,
    content: comment.content,
    owner: comment.employeeFullName
      ? {
          type: 'employee',
          id: comment.employeeId ?? '',
          fullName: comment.employeeFullName,
        }
      : comment.adminFullName
      ? {
          type: 'admin',
          id: comment.adminId ?? '',
          fullName: comment.adminFullName,
        }
      : null,
    subject: {
      id: comment.subject.id,
      entity: comment.subject.entity,
      title: comment.subject.title,
    },
    selectedReaction: comment.selectedEmoji ?? null,
    reactions: convertReactionsSummary(comment.reactions ?? []),
  };
};

const convertComments = (comments: INewsComment[]): NewsComment[] => {
  return comments.map(convertComment);
};

export const converters = {
  getNews: ({ data }: Awaited<ReturnType<typeof loaders.getNews>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertNews(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getNew: ({ data }: Awaited<ReturnType<typeof loaders.getNew>>) => {
    return convertNew(data);
  },
  getCategories: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getCategories>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertCategories(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getCategory: ({ data }: Awaited<ReturnType<typeof loaders.getCategory>>) => {
    return convertCategory(data);
  },
  getComments: ({ data }: Awaited<ReturnType<typeof loaders.getComments>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertComments(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
