import { IReaction, IReactionsSummary } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { Reaction, ReactionsSummary } from './reactions.types';
import { loaders } from './reactions.loaders';

export const convertReactionsSummary = (
  reactions: IReactionsSummary,
): ReactionsSummary => {
  return reactions.map((reaction) => ({
    emoji: reaction.emoji,
    count: reaction.count,
  }));
};

const convertReaction = (reaction: IReaction): Reaction => {
  return {
    reaction: reaction.emoji,
    firstName: reaction.firstName,
    lastName: reaction.lastName,
    fullName: [reaction.firstName, reaction.lastName].filter(Boolean).join(' '),
  };
};

const convertReactions = (reactions: IReaction[]): Reaction[] => {
  return reactions.map(convertReaction);
};

export const converters = {
  getReactions: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getReactions>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertReactions(data.data),
      summary: convertReactionsSummary(data.bar),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
