import { useMutation, useQueryClient } from '@tanstack/react-query';
import { newsKeys } from 'store/news';
import { loaders } from './reactions.loaders';

export const useCreateReaction = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createReaction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(newsKeys.all);
    },
  });
};

export const useDeleteReaction = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteReaction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(newsKeys.all);
    },
  });
};
