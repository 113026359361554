import { ComponentPropsWithoutRef, FC } from 'react';
import { ButtonBase, Stack } from '@mui/material';
import { Loader, ReactionsPreview, Typography } from 'melp-design/components';
import { SystemColors } from 'melp-design/style';
import moment from 'moment';
import { t } from 'i18next';

interface Props {
  title: string;
  message: string;
  date: string | null;
  hidden?: boolean;
  actions: Array<{
    label: string;
    onClick: () => void;
    loading?: boolean;
    variant?: 'regular' | 'primary' | 'danger';
  }>;
  reactionsPreview: ComponentPropsWithoutRef<typeof ReactionsPreview>;
}

export const CommentBlock: FC<Props> = ({
  title,
  message,
  date,
  hidden,
  actions,
  reactionsPreview,
}) => {
  return (
    <Stack gap={0.75} sx={{ width: '100%' }}>
      <Stack
        sx={{
          backgroundColor: SystemColors.grey[90],
          p: 1,
          borderRadius: 1,
          opacity: hidden ? 0.6 : undefined,
        }}
        alignSelf="flex-start"
      >
        <Typography variant="h4">{title}</Typography>
        <Typography>{message}</Typography>
      </Stack>

      <Stack gap={0.25} px={0.25}>
        {hidden ? (
          <Typography color="textSecondary">
            {t('comments.hidden.message')}
          </Typography>
        ) : null}

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          gap={4}
        >
          <Stack flexDirection="row" columnGap={2} rowGap={0.5} flexWrap="wrap">
            {date ? (
              <Typography color="textSecondary" sx={{ whiteSpace: 'nowrap' }}>
                {moment
                  .duration(moment(date).diff(moment(new Date())))
                  .humanize(true)}
              </Typography>
            ) : null}

            {actions.map(
              ({ label, onClick, loading, variant = 'regular' }, i) => (
                <ButtonBase
                  onClick={() => onClick()}
                  disabled={loading}
                  disableRipple
                  key={i}
                >
                  <Stack
                    flexDirection="row"
                    columnGap={0.25}
                    alignItems="center"
                  >
                    {loading ? <Loader size={15} /> : null}
                    <Typography
                      color={
                        variant === 'regular'
                          ? 'textSecondary'
                          : variant === 'primary'
                          ? 'primary'
                          : variant === 'danger'
                          ? 'error'
                          : undefined
                      }
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {label}
                    </Typography>
                  </Stack>
                </ButtonBase>
              ),
            )}
          </Stack>

          <ReactionsPreview {...reactionsPreview} />
        </Stack>
      </Stack>
    </Stack>
  );
};
